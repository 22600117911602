<template>
  <div id="submenu">
    <router-link :to="{ name: 'ServerSolutions' }" class="list-group-item" :class="{active: menu_list.server}">Server Solutions 
      <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right arrow" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
      </svg>
    </router-link>
      <div aria-labelledby="dropdownMenuLink">
        <router-link :to="{ name: 'GpuServer' }" class="dropdown-item" :class="{active: menu_list.gpu}">GPU Servers <i class="fa fa-chevron-right"></i></router-link>
        <router-link :to="{ name: 'StorageServer' }" class="dropdown-item" :class="{active: menu_list.storage}">Storage Servers <i class="fa fa-chevron-right"></i></router-link>
        <!-- <router-link :to="{ name: 'XilinxSolutions' }" class="dropdown-item" :class="{active: menu_list.xilinx}">Xilinx Real-Time Video Server <i class="fa fa-chevron-right"></i></router-link>
        <router-link :to="{ name: 'DataCenter' }" class="dropdown-item" :class="{active: menu_list.datacenter}">Xilinx Data Center Acceleration <i class="fa fa-chevron-right"></i></router-link> -->
        <!-- <router-link :to="{ name: 'ServerPromotions' }" class="dropdown-item" :class="{active: menu_list.promotion}" >Server Promotions <i class="fa fa-chevron-right"></i></router-link> -->
      </div>
    <!-- <router-link :to="{ name: 'MiningSolutions' }" class="list-group-item" :class="{active: menu_list.mining}">Mining Solutions</router-link> -->
    <router-link :to="{ name: 'IndustrialSolutions' }" class="list-group-item" :class="{active: menu_list.industrial}">Industrial Solutions </router-link>
    <router-link :to="{ name: 'GamingSolutions' }" class="list-group-item" :class="{active: menu_list.gaming}">Gaming Solutions </router-link>
    <router-link :to="{ name: 'EdgeSolutions' }" class="list-group-item" :class="{active: menu_list.edge}">Edge Solutions 
      <!-- <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right arrow" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
      </svg> -->
    </router-link>
    <!-- <div aria-labelledby="dropdownMenuLink">
      <router-link :to="{ name: 'DinoEdge' }" class="list-group-item" :class="{active: menu_list.dinoedge}">Edge Computing for Health & Safety </router-link>
    </div> -->
    <router-link :to="{ name: 'DigitalSolutions' }" class="list-group-item" :class="{active: menu_list.digital}">Digital Signage Solutions </router-link>
    <!-- <router-link :to="{ name: 'ServerConfig' }" class="list-group-item" :class="{active: menu_list.config}">System Configuration </router-link> -->
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: [ 'menu' ],
  setup(props) {
    const menu_list = ref({
        server: false,
        gpu: false,
        storage: false,
        xilinx: false,
        datacenter: false,
        minging: false,
        industrial: false,
        gaming: false,
        promotion: false,
        edge: false,
        dinoedge: false,
        digital: false,
        config: false
      })
      
    let serverList1 = new Set(["gpu", "storage", "xilinx", "datacenter", "promotion"])
    if (serverList1.has(props.menu)){
      menu_list.value["server"] = true
    }   
    menu_list.value[props.menu] = true

    if (props.menu == "dinoedge"){
      menu_list.value["edge"] = true
    }   
    // menu_list.value[props.menu] = true
     
    return { menu_list }
  }
}
</script>
<style scoped>
.arrow{
  float: right;
}
</style>